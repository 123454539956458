import React, { lazy } from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../config/sanity";

const SanityImage = ({
  input,
  objectFit = "contain",
  caption,
  showPlaceholder = true,
}) => {
  // const [imageLoaded, setImageloaded] = useState(false)
  // return null;

  // console.log(sanityConfig);
  const { asset, alt } = input;
  const { metadata } = asset;

  const width = 1500;
  const height = width / metadata.dimensions.aspectRatio;
  // const imagePlaceholder = getImage(asset);
  const imageData = getGatsbyImageData(
    asset,
    {
      width: width,
      height: height,
      fit: "FILLMAX",
      // placeholder: "BLURRED",
    },
    sanityConfig.sanity
  );
  // console.log(imageData);

  return (
    <figure>
      {showPlaceholder && (
        <div
          className='absolute bg-placeholder w-full h-full'
          style={{
            backgroundColor: metadata.palette.dominant.background,
          }}></div>
      )}

      <GatsbyImage
        image={imageData}
        alt={caption ? caption : ""}
        objectFit={objectFit}
      />

      {caption && <figcaption className='text-sm py-xs '>{caption}</figcaption>}
    </figure>
  );
};

export default SanityImage;
